import { graphql, Link } from "gatsby"
import * as React from "react"

import Container from "#components/global/container"
import Layout from "#components/global/layout"
import Seo from "#components/global/seo"
import { XCircleIcon } from "@heroicons/react/24/solid"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="404: Not found"
        description="Page not found"
        tags='projects'
      />
      <Container className="!pt-12">
        <div className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12">
          <XCircleIcon className="mx-auto mb-4 w-10 h-10 animate-wiggle text-red-800 dark:text-red-600"></XCircleIcon>
          <h1 className="mb-2 text-2xl font-bold tracking-tight leading-none text-stone-900 lg:mb-2 md:text-2xl xl:text-2xl dark:text-white">Roads… where we’re going we don’t need roads!</h1>
          <p className="text-sm text-gray-500 dark:text-gray-400">Yes you do. You've stumbled upon a dead alley. Please return <Link to="/" className="underline">home</Link>.</p>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
